<!-- 监测流程 -->
<template>
    <div style="padding:20px 24px 40px 24px">
        <div class="globalTitle" style="color:#FF830F;margin-bottom: 16px;">检测流程</div>
        <img :src="ossUrl + 'process.png'" class="imgTransition" alt="" style="width: 100%;">
        <div class="globalTitle" style="color:#FF830F;margin:30px 0 12px 0">检测声明</div>
        <div class="globalText">
            <p style="margin-bottom: 14px;"> (1) 本报告检测结果仅对本次送检样品负责供临床科学研究使用，如对检测结果持有异议，请于收到本报告起7个工作日内与我们取得联系；
            </p>
            <p style="margin-bottom: 14px;">(2)本报告检测结果与建议仅供临床参考，不能作为最终诊断结果，本报告结果相关解释需咨询临床医师；</p>
            <p style="margin-bottom: 14px;">
                (3)本报告所使用方法学受各种因素影响，如过样本量、数据库局限、抗菌药物使用、样本采集、样本保存、样本运输等因素均有可能导致本次检测未能涵盖样本中存在的所有免疫功能信息；</p>
            <p style="margin-bottom: 14px;">(4)本报告对该结果保密并依法保护受检者隐私，但因受检者个人原因出现信息外泄，本公司不承担相应责任；</p>
            (5)检测报告不得复制、私自转让、盗用、冒用、涂改或以其他形式篡改，均属无效，且本公司将追究上述行为的法律责任。
        </div>
    </div>
</template>
<script>
const ossUrl = 'https://kindstarbiotech.oss-cn-hangzhou.aliyuncs.com/rosefinch/static/h5/'
export default {
    data() {
        return {
            ossUrl: ossUrl,
        }
    },
    created() {
    },
    mounted() {
        // 页面置顶
        document.documentElement.scrollIntoView()
    },
    computed: {
    },
    methods: {
    },
}
</script>
<style lang="scss" scoped></style>
